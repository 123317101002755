import Api from '@api';
import { getUserRoleList, updateUserRoleList, getRoleLists ,addUser} from "../../../../../api/auth";
import { getActiveClientGroups } from "../../../../../api/client";

export default {
  name: 'editRole',
  components: {
  },
  props: {
    injectData: {
      type: Object,
      default: () => {
        return {
          roleId: ''
        }
      }
    },
  },
  data() {
    return {
      form: {
        NAME: '',
        USER_ID: '',
        NOTES: '',
        GROUP: '',
        CLIENT_VIS_GROUP: '',
        PASSWORD: '',
      },
      UNSELECTED: [],
      SELECTED: [],
      titles: ['待选择角色', '已有角色'],
      allclientgroups: [],
      allroles:[],
    }
  },
  mounted() {
    this.getAllRoles()
    // this.getUserRoles();
    this.getActiveClientGroupList();
  },
  methods: {
    async handleConfirm() {
      const { status, data, success } = await addUser(this.form)
      if (status == 200) {
        this.$emit('close')
        this.$message.success(success)
      }
    },
    async getAllRoles() {
      const params = {
        PAGE_NUM: 1,
        PAGE_SIZE: 100000
      }
      const { status, data } = await getRoleLists(params)
      if (status == 200) {
        //this.allclientgroups = data.data
        this.allroles = [...data.list.map(
          item => {
            return {
              key: item.GROUP_ID,
              label: item.GROUP_ID
            }
          }
        )];
      }
    },
    async getActiveClientGroupList() {
      const params = {

      }
      const { status, data } = await getActiveClientGroups(params)
      if (status == 200) {
        //this.allclientgroups = data.data
        this.allclientgroups = [...data.map(
          item => {
            return {
              key: item.clientGroup,
              label: item.lientGroup
            }
          }
        )];

        console.log("this.allclientgroups=" + this.allclientgroups);
      }
    },
    handleClose(params = {}) {
      this.$emit('close', params)
    }
  }
}