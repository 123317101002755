import { getFormConfig } from "./formConfig";

export const getTableColumn = vm => {
  const formConfigList = getFormConfig().filterFormConfig.filter(item => item.label);
  const list = [
    {
      field: "NICKNAME",
      title: "NICKNAME",
      tag: 'NICKNAME'
    },
    {
      field: "MOBILE",
      title: "MOBILE",
      tag: 'MOBILE'
    },
	 {
      field: "NAME",
      title: "NAME",
      tag: 'NAME'
    },
	{
      field: "AVATAR",
      title: "AVATAR",
      tag: 'AVATAR'
    },

    {
      field: "USER_ID",
      title: "绑定用户",
      tag: 'USER_ID'
    },

  ]

  const fixedList = [
    { type: 'seq', title: '序号', width: '60', tag: 'seq' },
  ];

  const opList = [
    {
      field: "edit",
      title: "操作",
      fixed: 'right',
      width: '200',
      tag: 'edit',
      _renderType: true,
      render: (h, { data }) => {
        let { row } = data;
        return (
          <div class={'edit'}>
            <el-button type="text" onClick={() => { vm.openModal('editUser', row) }}>操作</el-button>
          </div>
        );
      }
    },
  ]
  return {
    tableColumn: [...fixedList, ...list, ...opList]
  }
}