import Api from '@api';
import { getActiveClientGroups } from "../../../../../api/client";
import { getUserLists,bindWxUser } from "../../../../../api/auth";

export default {
  name: 'editRole',
  components: {
  },
  props: {
    injectData: {
      type: Object,
      default: () => {
        return {
          roleId: ''
        }
      }
    },
  },
  data() {
    return {
      form: {
        name: '',
        selecUserId: '',
      },
      UNSELECTED: [],
      SELECTED: [],
      titles: ['待选择角色', '已有角色'],
      allclientgroups: [],
      allusers: [],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    async handleConfirm() {
      const params = {
        WX_USER_ID: this.injectData.ID,
        USER_ID: this.form.selecUserId
      }
      const { status, data, success } = await bindWxUser(params)
      if (status == 200) {
        this.$emit('close')
        this.$message.success('Success')
      }
    },
    async getList() {
      const params = {
        PAGE_NUM: 1,
        PAGE_SIZE: 300
      }
      const { data, status, message } = await getUserLists(params)
      if (status === 200) {
        this.allusers = data.list
        // this.$refs.configTable.page.total = '1234'
      }
    },


    handleClose(params = {}) {
      this.$emit('close', params)
    }
  }
}