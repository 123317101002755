export const getFormConfig = (vm) => {
    return {
      filterFormConfig:[
        {
          "key": "NICKNAME",
          "label": "昵称",
          "type": "input",
          "placeholder": "昵称",
          "checked": false
        },
        {
          "key": "MOBILE",
          "label": "手机",
          "type": "input",
          "placeholder": "手机",
          "checked": false
        },
      ]
    }
}

